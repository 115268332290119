import React, { useState } from 'react';
import { useFarm } from "../contexts/FarmContext";
import './FarmBlockDropdown.css';

const FarmBlockDropdown = ({ onSelectBlock }) => {
  const {selectedFarm, setSelectedFarm, farms, demoFarm, isDemo, setIsDemo} = useFarm();
  const [selectedBlock, setSelectedBlock] = useState(null);

  const selectFarm = (code) => {
    // Update the selected farm and reset selected block when a farm is selected
    if (code === 'demo') {
      setSelectedFarm(demoFarm);
      setIsDemo(true);
    } else {
      const selected = farms.find(farm => farm.code === code);
      setSelectedFarm(selected);
      setIsDemo(false);
    }
    setSelectedBlock(null);
    onSelectBlock(null);
  };

  const selectBlock = (id) => {
    // Update the selected block when a block is selected
    const selected = selectedFarm.blocks.find((block) => block.id === id);
    setSelectedBlock(selected);
    // Pass the selected block back to the parent component
    onSelectBlock(selected);
  };

  return (
    <div className="row mr-0 ml-0">
        <div className="ml-1 d-flex align-items-center">
            <div className="dropdown">
                <button className="btn dropdown-toggle color-main bg-white" type="button" id="farm-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {isDemo ? 'Demo Farm' : selectedFarm.name}
                </button>

                <div className="dropdown-menu" aria-labelledby="farm-dropdown" id="farm-dropdown-menu">
                  {farms && (
                    farms.map((farm) => (
                      <a key={farm.code} className="dropdown-item" onClick={() => selectFarm(farm.code)}>
                        {farm.name}
                      </a>
                    ))
                  )}

                  {!farms.length && (
                  <a className="nav-link font-italic text-secondary dropdown-item" href={`${global.config.base_url}create/farm/`}>
                      Create Farm
                  </a>
                  )}

                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" onClick={() => selectFarm('demo')}>
                  Demo Farm
                  </a>
                </div>
            </div>
            
            <div className="dropdown ml-1">
                <button className="btn dropdown-toggle color-main bg-white ml-3" type="button" id="block-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {selectedBlock ? selectedBlock.name : 'All'}
                </button>

                <div className="dropdown-menu" aria-labelledby="block-dropdown" id="block-dropdown-menu">
                    <a className="dropdown-item" onClick={() => selectBlock(null)}>
                    All
                    </a>

                    {selectedFarm && selectedFarm.blocks && (
                      selectedFarm.blocks.map((block) => (
                        <a key={block.id} className="dropdown-item" onClick={() => selectBlock(block.id)}>
                          {block.name}
                        </a>
                      ))
                    )}
                </div>
            </div>
        </div>
    </div>
  );
};

export default FarmBlockDropdown;
