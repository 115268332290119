import React from 'react';
import { formatShortTime } from '../utils/formatDatetime';
import './WeatherBox.css';

function WeatherBox({ icon, weatherDescription, temperature, forecasts}) {
  icon = icon || "03d";
  weatherDescription = weatherDescription || "Not available";
  temperature = temperature || "-";

  return (
    <div className="card mt-1">
      <div className="card-body">
        <div className="row mr-0 ml-0 pt-2 pb-2 justify-content-between flex-nowrap">
          <div className="row mr-0 ml-0 align-items-center flex-nowrap pr-4">
            <img src={`weather/${icon}.svg`} alt="Icon" className="weather-icon" />
            <div className="pl-2 align-self-center">
              <div className="title-font text-nowrap">Current Weather</div>
              <div className="text-capitalize text-nowrap value-font">{weatherDescription}</div>
              <div className="value-font">{temperature}°C</div>
            </div>
          </div>

          <div className="row mr-0 ml-0 flex-nowrap overflow-y-auto">
            {forecasts.map((forecast, index) => (
              <div key={index} className={` ${index !== 0 ? 'pl-3' : ''}`}>
                <div className="text-center">{formatShortTime(forecast.datetime)}</div>
                <img src={`weather/${forecast.icon}.svg`} alt="Icon" className="weather-icon" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeatherBox;
