import React from 'react';
import FarmBlockDropdown from './FarmBlockDropdown';
import { Link } from 'react-router-dom';
import { useFarm } from  "../contexts/FarmContext";

const Appbar = ({ onSelectBlock }) => {
  const { selectedFarm } = useFarm();
  const handleExit = async () => {
    window.location.href = `${global.config.base_url}dashboard/?farm=${selectedFarm.code}`;
  };
  
  return (
    <div className="row mr-0 ml-0 d-flex justify-content-between" style={{ width: "100%", height: "60px", backgroundColor: "#52cea7", position: "fixed", zIndex: "999" }}>
      <div className="row mr-0 ml-0">
        <Link to={`${global.config.base_url}dashboard/`}>
          <img src="logo-white.png" alt="Logo" style={{ height: '30px', margin: '15px' }}/>
        </Link>
        <FarmBlockDropdown onSelectBlock={onSelectBlock} />
      </div>
      <div className="row mr-0 ml-0 d-flex align-items-center pr-3" onClick={handleExit} style={{ cursor: 'pointer' }}>
        <div className="text-white">
          Exit
        </div>
      </div>
    </div>
  );
};

export default Appbar;
