import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
    },
    labels: data.labels,
    colors: data.colors,
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: '70%',
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    animations: {
      enabled: true,
      dynamicAnimation: {
        enabled: true,
        speed: 2000
      }
    }
  });

  const series = data.series;

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const responsiveConfig = options.responsive || [];
  
      let updatedOptions = { ...options };
  
      for (const config of responsiveConfig) {
        if (
          windowWidth >= config.breakpoint &&
          windowWidth <= (config.nextBreakpoint || Number.MAX_VALUE)
        ) {
          updatedOptions = { ...updatedOptions, ...config.options };
          break;
        }
      }
  
      setOptions(updatedOptions);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ReactApexChart options={options} series={series} type="donut" style={{ display: 'flex', justifyContent: 'center' }} />;
};

export default DonutChart;
