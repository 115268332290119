import React from 'react';
import Icon from '@mdi/react';
import { mdiTrendingUp, mdiTrendingDown } from '@mdi/js';

const Badge = ({ value, increase }) => {
  const badgeClassName = 'badge font-smaller d-flex align-items-center';
  const backgroundClassName = value === 0
    ? `${badgeClassName} badge-secondary bg-grey2`
    : `${badgeClassName} ${increase ? 'badge-success bg-main2' : 'badge-danger bg-danger2'}`;

  return (
    <div className={backgroundClassName}>
      {value !== 0 && (increase ? <Icon path={mdiTrendingUp} size={0.5} className="mr-1"/> : <Icon path={mdiTrendingDown} size={0.5} className="mr-1"/>)}
      {value === 0 ? '-' : value}%
    </div>
  );
};

export default Badge;
