import Badge from '../../utils/badge';
import React, { useState, useEffect } from "react";
import axios from "../../auth/auth";
import { useFarm } from  "../../contexts/FarmContext";

function InfoBox() {
  const { selectedFarm } = useFarm();
  // const [ growthRate, setGrowthRate ] = useState(0);
  // const [ growthRatePercentage, setGrowthRatePercentage ] = useState(0);
  // const [ growthRateIncrease, setGrowthRateIncrease ] = useState(true);
  const [ avgHeight, setAvgHeight ] = useState(0);
  const [ avgHeightPercentage, setAvgHeightPercentage ] = useState(0);
  const [ avgHeightIncrease, setAvgHeightIncrease ] = useState(true);
  const [ avgCanopySize, setAvgCanopySize ] = useState(0);
  const [ avgCanopySizePercentage, setAvgCanopySizePercentage ] = useState(0);
  const [ avgCanopySizeIncrease, setAvgCanopySizeIncrease ] = useState(true);
  const [ avgTrunkDiameter, setAvgTrunkDiameter ] = useState(0);
  const [ avgTrunkDiameterPercentage, setAvgTrunkDiameterPercentage ] = useState(0);
  const [ avgTrunkDiameterIncrease, setAvgTrunkDiameterIncrease ] = useState(true);
  
  useEffect(() => {
    const fetchAverage = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/average/data/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          // setGrowthRate(data.growth_rate);
          // setGrowthRatePercentage(data.growth_rate_percentage);
          // setGrowthRateIncrease(data.growth_rate_is_increase);
          setAvgHeight(data.avg_height);
          setAvgHeightPercentage(data.height_percentage);
          setAvgHeightIncrease(data.height_is_increase);
          setAvgCanopySize(data.avg_canopy);
          setAvgCanopySizePercentage(data.canopy_percentage);
          setAvgCanopySizeIncrease(data.canopy_is_increase);
          setAvgTrunkDiameter(data.avg_trunk);
          setAvgTrunkDiameterPercentage(data.trunk_percentage);
          setAvgTrunkDiameterIncrease(data.trunk_is_increase);
        }
      } catch (error) {
        console.error("Error fetching average:", error);
      }
    };
    
    fetchAverage();
  }, [selectedFarm]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row mr-0 ml-0 d-flex flex-nowrap">
          <div className="icon-box">
            <img src={`icons/growth.svg`} alt="Icon" />
          </div>
          <div className="col-10 pl-2">
            <div className='text-nowrap title-font'>Growth Rate<span className='font-smaller font-weight-normal'> (Monthly)</span></div>
            <div className='row mr-0 ml-0 text-nowrap value-font'>-
              {/* <span className='pl-2'><Badge value={'N/A'} increase={true}/></span> */}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className='row mr-0 ml-0 d-flex align-items-center flex-nowrap pb-3'>
            <div className='col-8 pl-0 pr-0'>
              <div className='text-nowrap'>Avg. Height</div>
              <div className='value-font'>{avgHeight!=0?avgHeight:'-'} m</div>
            </div>
            <div className='col-4 pl-0 pr-0'>
              <Badge value={avgHeightPercentage} increase={avgHeightIncrease}/>
            </div>
          </div>

          <div className='row mr-0 ml-0 d-flex align-items-center flex-nowrap pb-3'>
            <div className='col-8 pl-0 pr-0'>
              <div className='text-nowrap'>Avg. Canopy Size</div>
              <div className='value-font'>{avgCanopySize!=0?avgCanopySize:'-'} m<sup>2</sup></div>
            </div>
            <div className='col-4 pl-0 pr-0'>
              <Badge value={avgCanopySizePercentage} increase={avgCanopySizeIncrease} />
            </div>
          </div>

          <div className='row mr-0 ml-0 d-flex align-items-center flex-nowrap pb-3'>
            <div className='col-8 pl-0 pr-0'>
              <div className='text-nowrap'>Avg. Trunk Diameter</div>
              <div className='value-font'>{avgTrunkDiameter!=0?avgTrunkDiameter:'-'} cm</div>
            </div>
            <div className='col-4 pl-0 pr-0'>
              <Badge value={avgTrunkDiameterPercentage} increase={avgTrunkDiameterIncrease} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoBox;
