import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = () => {
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        barHeight: '70%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        },
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
  });

  const series = [
    {
      data: [0,0,0,0,0,0],
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const responsiveConfig = options.responsive || [];
  
      let updatedOptions = { ...options };
  
      for (const config of responsiveConfig) {
        if (
          windowWidth >= config.breakpoint &&
          windowWidth <= (config.nextBreakpoint || Number.MAX_VALUE)
        ) {
          updatedOptions = { ...updatedOptions, ...config.options };
          break;
        }
      }
  
      setOptions(updatedOptions);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ReactApexChart options={options} series={series} type="bar" style={{ display: 'flex', justifyContent: 'center' }} />;
};

export default BarChart;
