import ListBox from "../../components/ListBox";
import GalleryBox from "../../components/GalleryBox";
import React, { useState, useEffect } from "react";
import axios from "../../auth/auth";
import { useFarm } from  "../../contexts/FarmContext";

function CurrentData() {
  const { selectedFarm } = useFarm();
  const [ workerCount, setWorkerCount ] = useState(0);
  const [ workers, setWorkers ] = useState([]);
  const [ logbookCount, setLogbookCount ] = useState(0);
  const [ logbooks, setLogbooks ] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/current/data/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setWorkerCount(data.worker_count);
          setWorkers(data.workers);
          setLogbookCount(data.logbook_count);
          setLogbooks(data.logbooks);
        }
      } catch (error) {
        console.error("Error fetching current data:", error);
      }
    };
    
    fetchData();
  }, [selectedFarm]);

  useEffect(() => {
    if (logbookCount > 0) {
      const workerElement = document.getElementById('worker');
      if (workerElement) {
        workerElement.classList.remove('max-height-100');
        workerElement.classList.add('max-height-lg-100');
      }
    } else {
      const workerElement = document.getElementById('worker');
      if (workerElement) {
        workerElement.classList.remove('max-height-lg-100');
        workerElement.classList.add('max-height-100');
      }
    }
  }, [logbookCount]);

  return (
    <div className="row m-0 pr-0 pl-0 col-12 pb-1 pb-xl-3 pt-0 pt-xl-3 bg-grey flex-1 flex-lg-2">
      <div id="worker" className="col-12 col-lg-6 pb-3 pb-lg-0 padding-lr-10 max-height-lg-100">
        <ListBox icon={'worker'} title={'Currently Working'} items={workers} count={workerCount} url={'worker/attendance/'} />
      </div>

      <div className="col-12 col-lg-6 padding-lr-10 bg-grey max-height-100">
        <GalleryBox key={JSON.stringify(logbooks)} icon={'logbook'} title={'Active Logbook'} items={logbooks} count={logbookCount} url={'logbook/'} />
      </div>
    </div>
  );
}

export default CurrentData;
  