import universalCookie from "universal-cookie";
import axios from "axios";
axios.defaults.withCredentials = true

const axiosInstance = axios.create();


axiosInstance.interceptors.response.use(
    response => {
        // If the response status is between 200 and 299, return the response
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
    },
    error => {
       
        if (error.response.status === 403) {
            const djangoUrl = global.config.base_url + "logout/";
            window.location.replace(djangoUrl);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
