import React, { useState, useEffect } from "react";
import Map from "../dashboard/Map";
import AppBar from "../../components/Appbar";
import CurrentData from "./CurrentData";
import Summary from "./Summary";
import OverlayInfo from "./OverlayInfo";
import { useFarm } from  "../../contexts/FarmContext";
import axios from "../../auth/auth";
import Loader from "../../utils/Loader";

function Dashboard() {
  const [selectedBlock, setSelectedBlock] = useState(null);
  const { selectedFarm } = useFarm();
  const [ stations, setStations ] = useState({});
  const [ fetchedStations, setFetchedStations ] = useState(false);

  const onSelectBlock = (block) => {
    setSelectedBlock(block);
  };

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get(global.config.base_url +'dashboard/api/sensor_station/?farm='+selectedFarm.id);

        if (response.status === 200) {
          const data = response.data;
          setStations(data.results);
          setFetchedStations(true);
        }
      } catch (error) {
        console.error("Error fetching station:", error);
      }
    };
    
    fetchStations();    
  }, [selectedFarm]);

  if (!fetchedStations) {
    return <Loader />;
  }
  return (
    <div className="d-flex flex-column">
      <AppBar onSelectBlock={onSelectBlock} />
      <div className="row mr-0 ml-0 d-flex" style={{ paddingTop: "60px", height: "100vh", backgroundColor: "rgb(248, 248, 248)" }}>
        <div className="col-12 col-xl-7 pr-0 pl-0 position-relative bg-grey d-flex flex-column vh-xl-70">
          <OverlayInfo stations={stations} />
          <div className="pr-2 d-flex flex-2">
            <Map selectedBlock={selectedBlock} stations={stations} />
          </div>
          <CurrentData />
        </div>
        
        <div className="col-12 col-xl-5 pr-0 pl-0 flex-1 d-flex flex-column bg-grey">
          <Summary />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
