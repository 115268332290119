import React, { useState, useEffect } from 'react';
import MissionBox from "../../components/MissionBox";
import RealTimeClock from "../../components/RealTimeClock";
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { useFarm } from  "../../contexts/FarmContext";
import axios from "../../auth/auth";

const OverlayInfo = ({ stations }) => {
  const { selectedFarm } = useFarm();
  const [ data, setData ] = useState({});
  const [ currentTime, setCurrentTime ] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/farm/data/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching farm data:", error);
      }
    };
    
    fetchData();
  }, [selectedFarm]);

  return (
    <div>
      <div className="col-12 pb-3 pb-sm-0 clock position-absolute">
        <div className="col-12 col-sm-10 pl-0">
          <RealTimeClock currentTime={currentTime} />
        </div>
        <div className="mr-0 ml-0 mt-1">
          <div className="d-flex align-items-center text-muted">
            <Icon path={mdiInformation} size={0.65} className="mr-1 ml-2"/>
            {selectedFarm.latest_ortho_id && selectedFarm.latest_ortho_date ?
              <span><i><a href={`${global.config.base_url}flight/mapping/${selectedFarm.latest_ortho_id}/`} target="_blank">Orthomap</a> last updated at {selectedFarm.latest_ortho_date??'-'}</i></span> :
              <span><i>No orthomap found</i></span>
            }
          </div>
        </div>
      </div>
      <MissionBox data={data} stations={stations} />
    </div>
  );
}

export default OverlayInfo;