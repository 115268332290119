import React from 'react';
import { formatTime } from '../utils/formatDatetime';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

function ListBox({ icon, title, count, items, url }) {
  return (
    <div className="card h-100 d-flex flex-column">
      <div className="card-body d-flex flex-column max-height-100">
        <div className="row mr-0 ml-0 d-flex flex-nowrap">
          <div className="icon-box">
            <img src={`icons/${icon}.svg`} alt="Icon" />
          </div>
          <div className="col-10 pl-2">
            <div className="text-nowrap d-flex align-items-center title-font">
                {title}
                <a className="row mr-0 ml-0 d-flex align-items-center" href={`${global.config.base_url}${url}`} target="_blank">
                  <Icon path={mdiOpenInNew} size={0.65} className="ml-1"/>
                </a>
            </div>
            <div className='value-font'>{count}</div>
          </div>
        </div>

        <div className="flex-grow-1 overflow-y-auto mt-2 pb-2">
          {items.length>0 ? 
            items.map((item, index) => (
              <p key={index} className="p-1 mb-0">
                {item.start_datetime && (
                  <span className="pr-3">{formatTime(item.start_datetime)}</span>
                )}
                {item.worker ? (
                  <span>{item.worker.name}</span>
                ) : (
                  <span>{item.name}</span>
                )}
              </p>
            )) : (
            <div className='text-muted font-italic'>There is no data.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListBox;
