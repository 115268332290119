import DonutChart from "../utils/DonutChart";
import BarChart from "../utils/BarChart";
import EmptyDonutChart from "../utils/EmptyDonutChart";
import EmptyBarChart from "../utils/EmptyBarChart";
import "./ChartBox.css";

function ChartBox({ type, icon, title, count, data }) {
    return (
        <div className="card h-100">
            <div className="card-body h-100">
                <div className="col-12 pl-0 pr-0 h-100 d-flex flex-column">
                    <div className="row mr-0 ml-0 d-flex flex-nowrap">
                        <div className="icon-box">
                            <img src={`icons/${icon}.svg`} colors={'#FFFFFF'} alt="Icon" />
                        </div>
                        <div className="col-10 pl-2">
                            <div className='text-nowrap title-font'>{title}</div>
                            <div className='value-font'>{count}</div>
                        </div>
                    </div>

                    {type==="donut"? (
                        <div className="auto-height-textarea mt-3 d-flex justify-content-center">
                            {data!=null ? (
                                <div className="donut-chart h-100">
                                    <DonutChart data={data} />
                                </div>
                            ):(
                                <div className="donut-chart">
                                    <EmptyDonutChart />
                                </div>
                            )}
                        </div>
                    ):(
                        <div className="auto-height-textarea d-flex justify-content-center">
                            {data!=null ? (
                                <div className="bar-chart h-100 pr-3">
                                    <BarChart data={data} />
                                </div>
                            ):(
                                <div className="bar-chart pr-3">
                                    <EmptyBarChart />
                                </div>
                            )}
                        </div>
                    )}
                    
                    {data!=null? (
                        <div className={`d-flex flex-wrap overflow-y-auto ${type === 'donut' ? 'mt-3' : ''} mb-1 pl-2 pr-2 h-100`}>
                            <div className="col-12 pl-2 pr-2">
                                {data.labels.map((label, index) => (
                                    <div key={index} className='d-flex align-items-center col-12 text-left'>
                                        <div>
                                            <span
                                                style={{
                                                        display: 'inline-block',
                                                        width: '12px',
                                                        height: '12px',
                                                        backgroundColor: data.colors[index],
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                    }}
                                            ></span>
                                        </div>
                                        <div>
                                            {label}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ):(null)}
                </div>
            </div>
        </div>
    );
}

export default ChartBox;
