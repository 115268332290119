import React, { useEffect, useRef, useState } from "react";
import { useFarm } from  "../../contexts/FarmContext";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "../../auth/auth";
import * as turf from '@turf/turf';
import './Map.css'
import { showTreeStatus } from "../../utils/showTreeStatus";
import { mdiOpenInNew } from '@mdi/js';

const Map = ({ selectedBlock, stations }) => {
  const { selectedFarm } = useFarm();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [activePopups, setActivePopups] = useState([]);
  
  selectedFarm.stations = stations;

  const popup = new mapboxgl.Popup({
    closeButton: false
  });

  let isPopupOpen = false;

  const showPopup = (e) => {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.description;
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
    setActivePopups((prevPopups) => [...prevPopups, popup]);
  };

  const removeAllPopups = () => {
    activePopups.forEach((popup) => popup.remove());
    setActivePopups([]);
  };

  useEffect(() => {
    if (selectedFarm) {
      removeAllPopups();
      fetchMapData();
    }
  }, [selectedFarm]);

  useEffect(() => {
    if (map.current) {
      if (selectedFarm.stations.length !== 0) {
        updateMapForStations(stations);
      } else {
      selectedFarm.stations = stations
      }
    }
  }, [stations]);

  useEffect(() => {
    if (map.current) {
      if (selectedBlock) {
        updateMapForSelectedBlock(selectedBlock);
      } else {
        updateMapForAllBlocks();
      }
    }
  }, [selectedBlock]);

  const fetchMapData = async () => {
    try {
      if (!selectedFarm) return;

      const [treesResponse, boundaryResponse] = await Promise.all([
        axios.get(global.config.base_url+'dashboard/api/slot/?farm='+selectedFarm.id+'&paired=true'),
        axios.get(global.config.base_url+'dashboard/api/farm/boundary/'+selectedFarm.id+'/')
      ]);

      if (treesResponse.status === 200 && boundaryResponse.status === 200) {
        const treesData = treesResponse.data.results;
        const boundaryData = boundaryResponse.data;

        mapboxgl.accessToken = global.config.mapbox_access_token;

        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v11",
          pitch: 55,
          antialias: true,
          projection: "globe",
          attributionControl: false,
          touchZoomRotate: false,
          doubleClickZoom: false,
          fadeDuration: 0,
          transitionDuration: 0,
        });

        const farmBoundary = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [boundaryData],
              },
            },
          ],
        };

        const bbox = turf.bbox(farmBoundary);

        map.current.on("load", () => {
          const layers = map.current.getStyle().layers;

          layers.forEach((layer) => {
            if (layer.type === "symbol" && layer.layout["text-field"]) {
              map.current.setLayoutProperty(layer.id, "visibility", "none");
            }
          });

          map.current.loadImage("/images/grass.jpg", (error, image) => {
            if (error) throw error;
            map.current.addImage("grass-texture", image);
          });

          map.current.loadImage("/images/marker.png", (error, image) => {
            if (error) throw error;
            map.current.addImage("custom-marker", image);
          });

          map.current.addLayer({
            id: "farm-layer",
            type: "fill",
            source: {
              type: "geojson",
              data: farmBoundary,
            },
            paint: {
              "fill-pattern": "grass-texture",
              "fill-opacity": 1,
            },
          });
          
          map.current.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            'tileSize': 512,
            'maxzoom': 14,
          });
          map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2.5 });

          map.current.fitBounds(bbox, {
            duration: 0,
            pitch: 45,
          });
          map.current.panBy([0, 20]);

          if (selectedBlock) {
            addBlock(selectedBlock);
          } else {
            selectedFarm.blocks.forEach((block) => {
              addBlock(block);
            });
          }

          if (selectedFarm.stations) {
            selectedFarm.stations.forEach((station) => {
              addStationMarker(station);
            });
          } else {
            selectedFarm.stations = stations;
          }

          treesData.forEach((tree, index) => {
            map.current.addLayer({
              id: `tree-layer-${index}`,
              type: "circle",
              source: {
                type: "geojson",
                data: {
                  type: "Feature",
                  'properties': {
                    'description':
                      `
                        <div>
                          <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div class="mr-2">
                              <strong>Tree Name: </strong><br>
                              <strong>Status: </strong><br>
                              <strong>Breed: </strong><br>
                            </div>
                            <div>
                              ${tree.name}<br>
                              ${showTreeStatus(tree.status)}<br>
                              ${(tree.current_tree && tree.current_tree.breed && tree.current_tree.breed.name) ? tree.current_tree.breed.name : '-'}<br>
                            </div>
                          </div>
                          <a href="${global.config.base_url}tree/analytics/${tree.name}/?farm=${selectedFarm.code}" target="_blank" style="display: flex; align-items: center;">
                            View Tree 
                            <svg width="12" height="12" viewBox="0 0 24 24" style="fill: #007bff;" class="ml-1">
                              <path d="${mdiOpenInNew}" />
                            </svg>
                          </a>
                        </div>
                      `
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [tree.longitude, tree.latitude],
                  },
                },
              },
              paint: {
                "circle-radius": 5, 
                "circle-color": tree.status===1?"#00E396":"#FF4560",
                "circle-opacity": 1,
              },
            });

            map.current.on('mouseenter', `tree-layer-${index}`, (e) => {
              map.current.getCanvas().style.cursor = 'pointer';
              showPopup(e);
            });
            
            map.current.on('mouseleave', `tree-layer-${index}`, () => {
              if (!isPopupOpen) {
                map.current.getCanvas().style.cursor = '';
                popup.remove();
              }
              isPopupOpen = false;
            });

            map.current.on('click', `tree-layer-${index}`, (e) => {
              showPopup(e);
              isPopupOpen = true;
            });
          });
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const addBlock = (block) => {
    const coords = [...block.coordinates];
    const coordinates = coords.map(coord => [coord[1], coord[0]]);
    coordinates.push(coordinates[0]);
    
    map.current.addLayer({
      id: `block-${block.id}`,
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [coordinates],
          },
        },
      },
      paint: {
        "line-color": block.color,
        "line-width": 2,
      },
    });
  };

  const updateMapForSelectedBlock = (block) => {
    map.current.getStyle().layers.forEach((layer) => {
      if (layer.id.startsWith('block-')) {
        map.current.removeLayer(layer.id);
        map.current.removeSource(layer.id);
      }
    });

    addBlock(block);
  };

  const updateMapForAllBlocks = () => {
    map.current.getStyle().layers.forEach((layer) => {
      if (layer.id.startsWith('block-')) {
        map.current.removeLayer(layer.id);
        map.current.removeSource(layer.id);
      }
    });

    selectedFarm.blocks.forEach((block) => {
      addBlock(block);
    });
  };

  const addStationMarker = (station) => {
    map.current.addLayer({
      id: `station-marker-${station.id}`,
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {
            description: `
              <div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div style="padding-right: 5px;">
                    <strong>Name: </strong>  
                  </div>
                  <div>
                    ${station.name}
                  </div>
                </div>
                <a href="${global.config.base_url}sensor_station/chart/average/${station.serial_number}/" target="_blank" style="display: flex; align-items: center;">
                  More Details   
                  <svg width="12" height="12" viewBox="0 0 24 24" style="fill: #007bff;" class="ml-1">
                    <path d="${mdiOpenInNew}" />
                  </svg>
                </a>
              </div>
            `,
          },
          geometry: {
            type: "Point",
            coordinates: [station.longitude, station.latitude],
          },
        },
      },
      layout: {
        "icon-image": "custom-marker",
        "icon-size": 0.05,
        "icon-allow-overlap": true,
      },
    });

    map.current.on('mouseenter', `station-marker-${station.id}`, (e) => {
      map.current.getCanvas().style.cursor = 'pointer';
      showPopup(e);
    });

    map.current.on('mouseleave', `station-marker-${station.id}`, () => {
      if (!isPopupOpen) {
        map.current.getCanvas().style.cursor = '';
        popup.remove();
      }
      isPopupOpen = false;
    });

    map.current.on('click', `station-marker-${station.id}`, (e) => {
      showPopup(e);
      isPopupOpen = true;
    });
  };

  const updateMapForStations = (stations) => {
    if(map.current.isStyleLoaded()) {
      map.current.getStyle().layers.forEach((layer) => {
      if (layer.id.startsWith('station-marker-')) {
          map.current.removeLayer(layer.id);
          map.current.removeSource(layer.id);
        }
      });
    }

    stations.forEach((station) => {
      addStationMarker(station);
    });
  };

  return <div ref={mapContainer} style={{ paddingRight: "0.5rem", width: "100%", height: "100%"}} />;
};

export default Map;
