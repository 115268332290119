import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './BarChart.css';

const padCategories = (labels) => {
  const paddedCategories = [...labels];
  const remainingEmptySlots = 6 - paddedCategories.length;
  if (remainingEmptySlots > 0) {
    for (let i = 0; i < remainingEmptySlots; i++) {
      paddedCategories.push('');
    }
  }
  return paddedCategories;
};

const BarChart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        }
      },
    },
    xaxis: {
      categories: padCategories(data.labels),
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    colors: data.colors,
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff'],
        fontWeight: 'normal',
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
      },
      offsetX: 0,
    },
    tooltip: {
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    },
    animations: {
      enabled: true,
      dynamicAnimation: {
        enabled: true,
        speed: 2000
      }
    },
  });

  const series = [
    {
      data: data.series,
    }
  ];

  const updateChartData = (data) => {
    setOptions({
      ...options,
      xaxis: {
        categories: padCategories(data.labels),
      },
      colors: data.colors,
    });
  };

  useEffect(() => {
    updateChartData(data);
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const responsiveConfig = options.responsive || [];
  
      let updatedOptions = { ...options };
  
      for (const config of responsiveConfig) {
        if (
          windowWidth >= config.breakpoint &&
          windowWidth <= (config.nextBreakpoint || Number.MAX_VALUE)
        ) {
          updatedOptions = { ...updatedOptions, ...config.options };
          break;
        }
      }
  
      setOptions(updatedOptions);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ReactApexChart options={options} series={series} type="bar" style={{ display: 'flex', justifyContent: 'center' }} />;
};

export default BarChart;
