import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import { FarmProvider } from "./contexts/FarmContext";

function App() {
  return (
    <FarmProvider>
      <Dashboard />
    </FarmProvider>
  );
}

export default App;
