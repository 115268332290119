import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle, mdiCircle } from '@mdi/js';
import { mdiCloseCircle } from '@mdi/js';
import "./MissionBox.css";

function MissionBox({ data, stations }) {
  return (
    <div className="col-3 col-sm-3 pb-3 pb-sm-0 mission position-absolute">
        <div className="card mt-4 text-white mission-box">
            <div className="card-body">
                <div className="mr-0 ml-0 mb-4">
                    <div className="font-bigger mb-1">Scheduled Missions</div>
                    {data.missions && data.missions.length > 0 ? (
                        data.missions.map((item, index) => (
                            <div key={index} className="d-flex align-items-center text-white mb-1">
                                <Icon path={item.overdue ? mdiCloseCircle : mdiCheckboxMarkedCircle} size={0.5} className={`mr-2 ${item.overdue ? 'color-danger2' : ''}`} />
                            <span>{item.name} {item.date}</span>
                            </div>
                        ))
                    ) : (
                    <small className="text-light-grey"><i>Empty</i></small>
                    )}
                </div>
                <div className="mr-0 ml-0 mb-4">
                    <div className="font-bigger mb-1">IoT Stations</div>
                    {stations.length > 0 ? (
                        stations.map((item, index) => (
                            <div key={index} className="d-flex align-items-center text-white mb-1">
                                {item.is_active ?
                                    <img src={`images/wifi.gif`} alt="Icon" className='wifi-icon mr-2'/> :
                                    <img src={`images/wifi.png`} alt="Icon" className='wifi-icon mr-2'/>
                                }
                            <div>{item.name}</div>
                            </div>
                        ))
                    ) : (
                    <small className="text-light-grey"><i>Empty</i></small>
                    )}
                </div>
                <div className="mr-0 ml-0">
                    <div className="font-bigger mb-1">Tree</div>
                    <div key={'healthy'} className="d-flex align-items-center text-white mb-1">
                        <Icon path={mdiCircle} size={0.4} className={`mr-2 color-main2`}/>
                        <span>Healthy {data.total_healthy}/{data.total_tree} </span><span className='text-light-grey font-smaller pl-1'>({data.healthy_percentage}%)</span>
                    </div>
                    <div key={'abnormal'} className="d-flex align-items-center text-white mb-1">
                        <Icon path={mdiCircle} size={0.4} className={'mr-2 color-danger2'}/>
                        <span>Abnormal {data.total_abnormal}/{data.total_tree} </span><span className='text-light-grey font-smaller pl-1'>({data.abnormal_percentage}%)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MissionBox;
