import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../auth/auth';
import Loader from '../utils/Loader';
import { useNavigate } from 'react-router-dom';

const FarmContext = createContext();

export const FarmProvider = ({ children }) => {
  const navigate = useNavigate();
  const [selectedFarm, setSelectedFarmState] = useState(null);
  const [farms, setFarms] = useState([]);
  const [demoFarm, setDemoFarm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDemo, setIsDemo] = useState(false);

  const setSelectedFarm = (farm) => {
    setSelectedFarmState(farm);
    setIsDemo(false);

    // Update the URL parameter when the dropdown value changes
    navigate({ search: `?farm=${farm.code}` });
  };

  useEffect(() => {
    const fetchDemoAndFarms = async () => {
      try {
        const [demoResponse, farmsResponse] = await Promise.all([
          axios.get(global.config.base_url + 'dashboard/api/demo/farm/'),
          axios.get(global.config.base_url + 'dashboard/api/farms/')
        ]);

        const demoFarmData = demoResponse.data['demo_farm'];
        const fetchedFarms = farmsResponse.data['results'];

        setDemoFarm(demoFarmData);
        setFarms(fetchedFarms);

        const urlParams = new URLSearchParams(window.location.search);
        const paramFarmCode = urlParams.get('farm');

        if (paramFarmCode) {
          const farmFromParams = fetchedFarms.find((farm) => farm.code === paramFarmCode);
          if (farmFromParams) {
            setSelectedFarmState(farmFromParams);
            setIsDemo(false);
          } else if (demoFarmData.code === paramFarmCode) {
            setSelectedFarmState(demoFarmData);
            setIsDemo(true);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchDemoAndFarms();
  }, []);

  if (loading) {
      return <Loader />;
  } else {
      return (
          <FarmContext.Provider value={{ selectedFarm, setSelectedFarm, farms, demoFarm, isDemo, setIsDemo }}>
              {children}
          </FarmContext.Provider>
      );
  }
};

export const useFarm = () => {
  const context = useContext(FarmContext);
  if (!context) {
    throw new Error('useFarm must be used within a FarmProvider');
  }
  return context;
};
