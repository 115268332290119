import WeatherBox from "../../components/WeatherBox";
import GaugeBox from "../../components/GaugeBox";
import ChartBox from "../../components/ChartBox";
import InfoBox from "./InfoBox";
import React, { useState, useEffect } from "react";
import axios from "../../auth/auth";
import { useFarm } from  "../../contexts/FarmContext";
import './Summary.css'

function Summary() {
  const { selectedFarm } = useFarm();
  const [ currentWeather, setCurrentWeather ] = useState([]);
  const [ forecastWeather, setForecastWeather ] = useState([]);
  const [ stressLevel, setStressLevel ] = useState([0]);
  const [ inventory, setInventory ] = useState([0]);
  const [ totalBreed, setTotalBreed ] = useState([0]);
  const [ totalSymptom, setTotalSymptom ] = useState([0]);
  const [ breeds, setBreeds ] = useState([]);
  const [ symptoms, setSymptoms ] = useState([]);
  const isBreedEmpty = breeds.length === 0;
  const isSymptomEmpty = symptoms.length === 0;
  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/weather/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setCurrentWeather(data[0]);
          setForecastWeather(data.slice(1));

          if (data[0]) {
            fetchSummary();
          }
        }
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };

    const fetchSummary = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/summary/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setStressLevel(data.stress_level);
          setInventory(data.inventory);
          setTotalBreed(data.total_breed);
          setTotalSymptom(data.total_symptom);
          setBreeds(data.breeds);
          setSymptoms(data.symptoms);
        }
      } catch (error) {
        console.error("Error fetching summary:", error);
      }
    };

    fetchWeather();
  }, [selectedFarm]);

  const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#22A7EB', '#F07167', '#9BDCFF'];
  
  const breedLabels = [];
  const breedSeries = [];

  breeds.forEach((breed) => {
    breedLabels.push(breed.name);
    breedSeries.push(breed.count);
  });

  const breedData = {
    labels: breedLabels,
    series: breedSeries,
    colors: colors,
  };

  const symptomLabels = [];
  const symptomSeries = [];

  symptoms.forEach((symptom) => {
    symptomLabels.push(symptom.name);
    symptomSeries.push(symptom.count);
  });

  const symptomData = {
    labels: symptomLabels,
    series: symptomSeries,
    colors: colors,
  };

  return (
    <div className="pt-2 pt-xl-3 h-100">
      <div className="col-12 pb-3 pb-sm-3 padding-lr-10 bg-grey">
        <WeatherBox icon={currentWeather.icon} weatherDescription={currentWeather.weather_description} temperature={currentWeather.temperature} forecasts={forecastWeather}/>
      </div>

      <div className="row mr-0 ml-0 pr-0 pl-0 col-12 pb-1 pb-sm-3 bg-grey">
        <div className="col-12 col-sm-4 pb-3 pb-sm-0 padding-lr-10">
          <InfoBox />
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0 padding-lr-10">
          <GaugeBox icon={'stress'} title={'Stress Level'} value={stressLevel} />
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0 padding-lr-10">
          <GaugeBox icon={'inventory'} title={'Inventory'} value={inventory} color={'#00E396'} unit={'%'}/>
        </div>
      </div>

      <div className="row mr-0 ml-0 pr-0 pl-0 col-12 pb-3 apex-height bg-grey">
        <div className="col-12 col-sm-8 pb-3 pb-sm-0 h-100 padding-lr-10">
          {isSymptomEmpty ? (
            <ChartBox type={'bar'} icon={'symptom'} title={'Symptoms'} count={totalSymptom} data={null} />
          ) : (
            <ChartBox type={'bar'} icon={'symptom'} title={'Symptoms'} count={totalSymptom} data={symptomData} />
          )}
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0 h-100 padding-lr-10 bg-grey">
          {isBreedEmpty ? (
            <ChartBox type={'donut'} icon={'breed'} title={'Total Breed'} count={totalBreed} data={null} />
          ) : (
            <ChartBox type={'donut'} icon={'breed'} title={'Total Breed'} count={totalBreed} data={breedData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Summary;
