import React from 'react';
import ImageSlider from '../utils/Slider';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

function GalleryBox({ icon, title, count, items, url }) {
  return (
    <div className="card h-100 d-flex flex-column">
      <div className="card-body pb-0 d-flex flex-column max-height-100">
        <div className="row mr-0 ml-0 d-flex flex-nowrap">
          <div className="icon-box">
            <img src={`icons/${icon}.svg`} alt="Icon" />
          </div>
          <div className="col-10 pl-2">
            <div className="text-nowrap d-flex align-items-center title-font">
                {title}
                <a className="row mr-0 ml-0 d-flex align-items-center" href={`${global.config.base_url}${url}`} target="_blank">
                  <Icon path={mdiOpenInNew} size={0.65} className="ml-1"/>
                </a>
            </div>
            <div className="value-font">{count}</div>
          </div>
        </div>

        {items.length>0 ? (
          <div className="flex-grow-1 mt-2 d-flex flex-wrap align-content-center">
            <ImageSlider items={items} />
          </div>
        ) : (
          <div className='pt-2 pb-2 text-muted font-italic'>There is no data.</div>
        )}
      </div>
    </div>
  );
}

export default GalleryBox;
