import GaugeChart from "../utils/GaugeChart";

function GaugeBox({ icon, title, value, color, unit }) {
    const chartColor = color || '#008FFB';
    const chartUnit = unit || '';

    return (
        <div className="card">
            <div className="card-body">
                <div className="row mr-0 ml-0 d-flex flex-nowrap">
                    <div className="icon-box">
                        <img src={`icons/${icon}.svg`} alt="Icon" />
                    </div>
                    <div className="col-10 pl-2">
                        <div className='text-nowrap title-font'>{title}</div>
                        <div className='value-font'>{value!=0?value:"-"}{chartUnit}</div>
                    </div>
                </div>
                
                <div className="auto-height-textarea mt-2">
                    <GaugeChart value={value} color={chartColor} unit={chartUnit}/>
                </div>
            </div>
        </div>
    );
}

export default GaugeBox;
